export const colors = {
  lighterPrimary: {
    default: '#F8FAFC',
    contrast: '#02315B',
  },

  lightPrimary: {
    default: '#8FACD9',
    contrast: '#02315B',
  },

  primary: {
    default: '#222222',
    contrast: '#FFFFFF',
  },

  darkPrimary: {
    default: '#006954',
    contrast: '#FFFFFF',
  },
  white: {
    default: '#ffffff',
    contrast: '#006954',
  },

  red: {
    default: '#E30613',
    contrast: '#FFFFFF',
  },

  midPrimary: {
    default: '#009CDE',
    contrast: '#FFFFFF',
  },

  lightGreen: {
    default: '#B8CCA4',
    contrast: '#FFFFFF',
  },

  green: {
    default: '#B2E76A',
    contrast: '#006954',
  },

  darkGreen: {
    default: '#8dd553',
    contrast: '#FFFFFF',
  },

  pink: {
    default: '#E68699',
    contrast: '#FFFFFF',
  },

  orange: {
    default: '#E07800',
    contrast: '#FFFFFF',
  },

  yellow: {
    default: '#F2A900',
    contrast: '#FFFFFF',
  },

  grey: {
    default: '#fff',
    contrast: '#222222',
  },

  lightGrey: {
    default: '#f2f2f2',
    contrast: '#FFFFFF',
  },

  transparent: {
    default: 'transparent',
    contrast: 'transparent',
  },
};

export default colors;

export type ThemeColors = typeof colors;
export type ThemeColorsKeys = keyof typeof colors;
